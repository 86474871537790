<template>
</template>

<script>
export default {
  inject: ['mapbox', 'map'],

  props: {
    speed: {
      type: Number,
      default: 3
    },

    maxZoom: {
      type: Number,
      default: 5
    }
  },

  data () {
    return {
      interacting: false
    }
  },

  mounted () {
    this.map.on('mousedown', () => this.pause())
    this.map.on('mouseup', () => this.resume())
    this.map.on('dragend', () => this.resume())
    this.map.on('pitchend', () => this.resume())
    this.map.on('rotateend', () => this.resume())
    this.map.on('moveend', () => this.spin())

    this.spin()
  },

  methods: {
    pause () {
      this.interacting = true
    },

    resume () {
      this.interacting = false
      this.spin()
    },

    spin () {
      const zoom = this.map.getZoom()

      if (!this.interacting && zoom < this.maxZoom) {
        const center = this.map.getCenter()
        center.lng -= this.speed

        this.map.easeTo({ center: center, duration: 1000, easing: (n) => n })
      }
    }
  }
}
</script>
