<template>
  <div tabindex="-1" @keydown.esc="$emit('close')">
    <MapWindow class="station-map-window map-absolute top left" :class="{ 'puc-map-window': station.type === 'puc' }">
      <MapWindowSection :expandable="false">
        <StationInfo :station="station" />
      </MapWindowSection>

      <MapWindowSection v-if="station.type === 'stream_youtube'" :expanded="true" class="tight">
        <template v-slot:header>
          YouTube Stream
        </template>

        <YouTube v-if="stationData.videoUrl" :src="stationData.videoUrl" :width="350" :height="197" :vars="{ autoplay: 1, modestbranding: 1, playsinline: 1, controls: 0 }" />
        <p v-else class="py-2 px-3 text-muted fst-italic m-0">
          Loading YouTube stream...
        </p>
      </MapWindowSection>

      <MapWindowSection v-if="station.type === 'puc'" :expanded="true">
        <template v-slot:header>
          PUC Sensors
        </template>

        <StationSensorInfo :station="station" :autoload="true" />
      </MapWindowSection>

      <MapWindowSection :expanded="true">
        <template v-slot:header>
          Latest Detections
        </template>

        <StationDetectionInfo ref="detections" :station="stationData" />
      </MapWindowSection>
    </MapWindow>

    <CloseButton @click="$emit('close')" class="position-absolute top-0 end-0 m-3" />
  </div>
</template>

<script>
import CloseButton from '@/components/controls/CloseButton.vue'

import MapWindow from '@/components/MapWindow.vue'
import MapWindowSection from '@/components/MapWindowSection.vue'
import StationDetectionInfo from '@/components/StationDetectionInfo.vue'
import StationSensorInfo from '@/components/StationSensorInfo.vue'
import StationInfo from '@/components/StationInfo.vue'

import YouTube from 'vue3-youtube'

import StationInfoQuery from '@/queries/StationInfo.graphql'

const REFRESH_INTERVAL = 60 * 1000 // 1 minute

export default {
  props: {
    station: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      fullStation: null
    }
  },

  mounted () {
    this.$el.focus()
  },

  apollo: {
    fullStation: {
      query: StationInfoQuery,
      pollInterval: REFRESH_INTERVAL,

      update: data => data.station,

      variables () {
        return { id: this.station.id }
      }
    }
  },

  computed: {
    stationData () {
      return {
        ...this.station,
        ...this.fullStation
      }
    }
  },

  components: {
    CloseButton,
    MapWindow,
    MapWindowSection,
    StationDetectionInfo,
    StationSensorInfo,
    StationInfo,
    YouTube
  }
}
</script>
