<template>
  <div ref="waveform" class="waveform w-100">
    <div ref="spectrogram" class="waveform-spectrogram" :style="{ width: `${width}px` }" @click="toggle"></div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import SpectrogramPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.spectrogram'
import PlayheadPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.playhead'
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions'

import Color from 'color'
import colormap from 'colormap'

const COLORS = colormap({
  colormap: 'jet',
  nshades: 256,
  format: 'float'
})

export default {
  props: {
    url: {
      type: String,
      required: true
    },

    startTime: {
      type: Number,
      required: false
    },

    endTime: {
      type: Number,
      required: false
    },

    width: {
      type: Number,
      default: 512
    },

    height: {
      type: Number,
      default: 32
    },

    autoplay: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: '#429488'
    }
  },

  data () {
    return {
      playing: false
    }
  },

  mounted () {
    this.wavesurfer = WaveSurfer.create({
      container: this.$refs.waveform,
      responsive: true,
      width: this.height,
      height: this.height,
      normalize: true,
      plugins: [
        SpectrogramPlugin.create({
          wavesurfer: this.wavesurfer,
          container: this.$refs.spectrogram,
          fftSamples: this.fftSamples,
          colorMap: COLORS
        }),

        // PlayheadPlugin.create({
        //   moveOnSeek: false
        // }),

        RegionsPlugin.create()
      ]
    })

    this.wavesurfer.on('ready', () => {
      this.$emit('loaded')

      if (typeof this.startTime !== 'undefined') {
        const startTime = Math.max(0, this.startTime)
        const endTime = this.endTime

        this.wavesurfer.addRegion({
          start: startTime,
          end: endTime,
          color: Color(this.color).alpha(0.4),
          loop: false,
          drag: false,
          resize: false
        })

        // this.wavesurfer.playhead.setPlayheadTime(startTime);
      }

      if (this.autoplay) {
        this.play()
      }
    })

    this.wavesurfer.on('error', (message) => {
      console.error(`Soundscape failed to load: ${message}`)
    })

    this.wavesurfer.on('pause', () => this.playing = false)
    this.wavesurfer.on('play', () => this.playing = true)

    this.wavesurfer.on('finish', () => this.$emit('finished'))
    // this.wavesurfer.on('region-out', () => this.$emit('finished'))

    this.$emit('loading')
    this.wavesurfer.load(this.url)
  },

  destroyed () {
    this.wavesurfer.destroy()
  },

  computed: {
    fftSamples () {
      if (window.devicePixelRatio == 1) {
        return 512
      } else {
        return 1024
      }
    }
  },

  methods: {
    playRegion () {
      const region = Object.values(this.wavesurfer.regions.list)[0]
      const currentTime = this.wavesurfer.getCurrentTime()

      if (region && currentTime >= region.start && currentTime <= region.end) {
        region.play()
      } else {
        this.wavesurfer.play()
      }
    },

    pause () {
      this.wavesurfer.pause()
    },

    play () {
      this.wavesurfer.play()
    },

    toggle () {
      if (this.wavesurfer.isPlaying()) {
        this.pause()
      } else {
        this.play()
      }
    }
  },

  watch: {
    url () {
      this.wavesurfer.clearRegions()

      this.$emit('loading')
      this.wavesurfer.load(this.url)
    }
  }
}
</script>
