import IconFixed from '@/images/markers/fixed.svg'
import IconPUC from '@/images/markers/puc.svg'
import IconYouTube from '@/images/markers/youtube.svg'
import IconAudio from '@/images/markers/audio.svg'
import IconMobile from '@/images/markers/mobile.svg'

export const StationTypes = {
  birdnetpi: 'BirdNET-Pi',
  puc: 'PUC',
  stream_youtube: 'Stream (YouTube)',
  stream_audio: 'Stream (Audio)',
  app: 'BirdWeather App'
}

export function stationType (type) {
  return StationTypes[type]
}

export const StationColors = {
  birdnetpi: '#25bf21',
  puc: '#35513e',
  stream_youtube: '#da0101',
  stream_audio: '#2e84cb',
  app: '#ad15b0'
}

export function stationColor (type) {
  return StationColors[type]
}

export const StationIcons = {
  birdnetpi: IconFixed,
  puc: IconPUC,
  stream_youtube: IconYouTube,
  stream_audio: IconAudio,
  app: IconMobile,
  user: IconMobile,
  guest: IconMobile,
  mobile: IconMobile
}

export function stationIcon (type) {
  return StationIcons[type]
}
