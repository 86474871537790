<template>
  <div class="station-info d-flex align-items-center">
    <div class="flex-fill">
      <header class="station-info-header">
        <h5 class="fw-bold mb-1">{{ station.name }}</h5>

        <p>
          <em class="text-muted">#</em>{{ station.id }}
          <span class="text-muted">- </span>
          <small class="text-muted">{{ stationType }}</small>
        </p>
      </header>

      <div class="station-info-main">
        <!-- <StationWeatherInfo ref="weather" :station="station" :autoload="autoload" /> -->

        <template v-if="puc">
          <!-- <StationSensorInfo ref="sensors" :station="station" :autoload="autoload" /> -->
        </template>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
// import StationSensorInfo from '@/components/StationSensorInfo'
// import StationWeatherInfo from '@/components/StationWeatherInfo'

import { stationType } from '@/lib/stations'

export default {
  props: {
    station: {
      type: Object,
      required: true
    },

    autoload: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    stationType () {
      return stationType(this.station.type)
    },

    fixedStation () {
      return this.station.type !== 'app'
    },

    puc () {
      return this.station.type === 'puc'
    }
  },

  methods: {
    loadData () {
      this.$refs.weather.loadData()

      if (this.puc) {
        this.$refs.sensors.loadData()
      }
    }
  },

  components: {
    // StationDetectionInfo,
    // StationSensorInfo,
    // StationWeatherInfo
  }
}
</script>
