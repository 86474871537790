<template>
  <Transition name="fade">
    <LoadingStatus v-if="$apollo.queries.stations.loading" status="Loading stations..." :indeterminate="true" />
  </Transition>
</template>

<script>
import LoadingStatus from '@/components/controls/LoadingStatus.vue'

import StationsQuery from '@/queries/Stations.graphql'

import { StationTypes, StationIcons } from '@/lib/stations'

export default {
  inject: ['mapbox', 'map'],

  props: {
    activeStation: {
      type: Object
    }
  },

  data () {
    return {
      stations: {}
    }
  },

  mounted () {
    if (this.map._loaded) {
      this.initializeMapLayers()
    } else {
      this.map.on('load', () => {
        this.initializeMapLayers()
      })
    }
  },

  activated () {
    for (const type of this.stationTypes) {
      this.toggleLayer(type, true)
    }
  },

  deactivated () {
    for (const type of this.stationTypes) {
      this.toggleLayer(type, false)
    }
  },

  methods: {
    initializeMapLayers () {
      this.map.addSource('stations', {
        type: 'geojson',
        data: this.geoJSONData
      })

      this.map.on('click', this.stationTypes, (e) => {
        const feature = e.features[0]
        const station = this.stationsById[feature.properties.id]

        this.$emit('clickStation', station)
      })

      this.map.on('mouseenter', this.stationTypes, (e) => {
        this.map.getCanvas().style.cursor = 'pointer'
      })

      this.map.on('mouseleave', this.stationTypes, (e) => {
        this.map.getCanvas().style.cursor = ''
      })

      for (const type of this.stationTypes) {
        const image = new Image(26, 41)

        image.addEventListener('load', () => {
          this.map.addImage(`station-${type}`, image)

          this.map.addLayer({
            id: type,
            source: 'stations',
            filter: ['==', 'type', type],
            type: 'symbol',
            layout: {
              'icon-image': `station-${type}`,
              'icon-allow-overlap': true,
              'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.25, 12, 1],
              'icon-anchor': 'bottom'
            },
            paint: {
              'icon-opacity': 1.0
            }
          })
        })

        image.src = StationIcons[type]
      }
    },

    toggleLayer (layer, visible) {
      if (this.map.getLayer(layer)) {
        const visibility = visible ? 'visible' : 'none'
        this.map.setLayoutProperty(layer, 'visibility', visibility)
      }
    }
  },

  computed: {
    stationTypes () {
      return Object.keys(StationTypes).reverse()
    },

    stationsById () {
      return this.stations.nodes.reduce((acc, s) => {
        acc[s.id] = s
        return acc
      }, {})
    },

    activeStations () {
      if (this.stations.nodes) {
        return this.stations.nodes.filter(s => s.coords)
      } else {
        return []
      }
    },

    geoJSONData () {
      return {
        type: 'FeatureCollection',
        features: this.activeStations.map((station) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [station.coords.lon, station.coords.lat],
            },
            properties: {
              id: station.id,
              type: station.type
            }
          }
        })
      }
    }
  },

  apollo: {
    stations: {
      query: StationsQuery
    }
  },

  watch: {
    stations () {
      const source = this.map.getSource('stations')
      if (source) { source.setData(this.geoJSONData) }
    },

    activeStation (station) {
      const opacity = station ? ['case', ['==', ['get', 'id'], station.id], 1.0, 0.2] : 1.0

      for (const type of this.stationTypes) {
        this.map.setPaintProperty(type, 'icon-opacity', opacity)
      }
    }
  },

  components: {
    LoadingStatus
  }
}
</script>
