<template>
  <Map ref="map" v-model:zoom="zoom">
    <SolarTerminator ref="solar" :time="now" />

    <KeepAlive><BirdNETMapLayer v-if="layers.birdnet" /></KeepAlive>
    <KeepAlive><BirdWeatherStationsMapLayer v-if="layers.birdweatherStations" @clickStation="selectStation" :activeStation="activeStation" /></KeepAlive>
    <KeepAlive><BirdWeatherLiveDetectionsMapLayer v-if="layers.birdweatherLiveDetections" @newDetection="newDetection" @clickDetection="selectDetection" /></KeepAlive>

    <Transition name="fade">
      <DetectionSoundscape v-if="layers.birdweatherLiveDetections && activeDetection" :detection="activeDetection" @loading="setBusy(true)" @finished="setBusy(false)" />
    </Transition>

    <Transition name="fade">
      <StationView v-if="activeStation" :station="activeStation" @close="selectStation(null)" />
    </Transition>

    <Transition name="fade">
      <LayersPanel v-if="!activeStation" v-model:layers="layers" class="map-absolute top right" />
    </Transition>

    <SpeciesMarkers />
    <!-- <SpinGlobe :speed="6" /> -->
  </Map>
</template>

<script>
import BirdNETMapLayer from '@/components/layers/BirdNETMapLayer.vue'
import BirdWeatherLiveDetectionsMapLayer from '@/components/layers/BirdWeatherLiveDetectionsMapLayer.vue'
import BirdWeatherStationsMapLayer from '@/components/layers/BirdWeatherStationsMapLayer.vue'
import SolarTerminator from '@/components/layers/SolarTerminator.vue'

import DetectionSoundscape from '@/components/DetectionSoundscape.vue'
import LayersPanel from '@/components/controls/LayersPanel.vue'
import StationView from '@/components/StationView.vue'

import SpeciesMarkers from '@/components/behaviors/SpeciesMarkers.vue'
import SpinGlobe from '@/components/behaviors/SpinGlobe.vue'

import Map from '@/components/Map.vue'

export default {
  data () {
    return {
      zoom: 6,
      originalZoom: null,

      activeDetection: null,
      activeStation: null,

      autoplaySoundscapes: false,
      soundscapeBusy: false,

      layers: {
        birdnet: false,
        birdweatherStations: true,
        birdweatherLiveDetections: false
      },

      now: new Date()
    }
  },

  mounted () {
    setInterval(() => {
      this.now = new Date()
    }, 60000)
  },

  methods: {
    selectDetection (detection) {
      this.$refs.map.flyTo([detection.coords.lon, detection.coords.lat])
      this.activeDetection = detection
    },

    newDetection (detection) {
      if (this.autoplaySoundscapes && !this.soundscapeBusy) {
        this.selectDetection(detection)
      }
    },

    selectStation (station) {
      this.activeStation = station

      if (station) {
        this.originalZoom = this.zoom
        this.$refs.map.flyTo([station.coords.lon, station.coords.lat], { zoom: Math.max(12, this.zoom) })
        this.$refs.map.lockPosition()
      } else {
        this.$refs.map.zoomTo(this.originalZoom || 6)
        this.$refs.map.unlockPosition()
      }
    },

    setBusy (busy) {
      this.soundscapeBusy = busy
    }
  },

  components: {
    BirdNETMapLayer,
    BirdWeatherLiveDetectionsMapLayer,
    BirdWeatherStationsMapLayer,
    DetectionSoundscape,
    LayersPanel,
    Map,
    SolarTerminator,
    SpeciesMarkers,
    SpinGlobe,
    StationView
  }
}
</script>
