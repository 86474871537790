<template>
  <span v-if="hasDefaultSlot" :class="htmlClass">
    <input class="form-check-input" type="checkbox" :id="id" v-model="model" :disabled="disabled" />
    <label v-if="hasDefaultSlot" class="form-check-label" :for="id">
      <slot />
    </label>
  </span>

  <input v-else class="form-check-input" type="checkbox" :id="id" v-model="model" :disabled="disabled" />
</template>

<script>
export default {
  props: {
    modelValue: {
      default: true
    },

    checked: {
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    trueValue: {
      default: true
    },

    falseValue: {
      default: false
    }
  },

  data() {
    return {
      uid: null
    }
  },

  mounted() {
    this.uid = this.$.uid
  },

  methods: {
    toggle: function (event) {
      let isChecked = event.target.checked

      if (this.checked instanceof Array) {
        let newValue = [...this.checked]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('update', newValue)
      } else {
        this.$emit('update', isChecked ? this.trueValue : this.falseValue)
      }
    }
  },

  computed: {
    model: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) }
    },

    id () {
      return `checkbox-${this.uid}`
    },

    isChecked() {
      if (this.checked instanceof Array) {
        return this.checked.includes(this.modelValue)
      } else {
        return this.checked === this.trueValue
      }
    },

    hasDefaultSlot() {
      return !!this.$slots.default
    },

    htmlClass() {
      return this.hasDefaultSlot ? 'form-check' : ''
    }
  }
}
</script>
