<template>
  <div class="map-window-section" :class="{ expanded: isExpanded }">
    <header v-if="$slots.header" class="map-window-section-header">
      <h6><slot name="header" /></h6>

      <button v-if="expandable" class="btn btn-toggle" @click="toggle">
        <font-awesome-icon icon="chevron-down" />
      </button>
    </header>

    <div v-if="isExpanded || !expandable" class="map-window-section-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expandable: {
      type: Boolean,
      default: true
    },

    expanded: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isExpanded: this.expanded
    }
  },

  methods: {
    toggle () {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>
