<template>
  <div class="shadow-sm layers-panel">
    <div>
      <label class="layer">
        Stations
        <Checkbox v-model="layers.birdweatherStations" />
      </label>
    </div>

    <div>
      <label class="layer">
        Live Detections
        <Checkbox v-model="layers.birdweatherLiveDetections" />
      </label>
    </div>

    <div>
      <label class="layer">
        BirdNET
        <Checkbox v-model="layers.birdnet" />
      </label>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue'

export default {
  props: {
    layers: {
      type: Object,
      required: true
    }
  },

  components: {
    Checkbox
  }
}
</script>
