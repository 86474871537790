<template>
  <div class="detection-soundscape map-window map-absolute bottom left">
    <Detection class="map-window" :detection="detection" />

    <Spectrogram class="p-2" :width="384" ref="spectrogram" :url="detection.soundscape.url" :height="24" :autoplay="true" :startTime="detection.soundscape.startTime" :endTime="detection.soundscape.endTime" :color="detection.species.color" @loading="$emit('loading')" @finished="$emit('finished')" />
  </div>
</template>

<script>
import Detection from '@/components/Detection.vue'
import Spectrogram from '@/components/Spectrogram.vue'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    }
  },

  components: {
    Detection,
    Spectrogram
  }
}
</script>
