<template>
  <div>
    <p v-if="station.latestDetectionAt">
      Last Detection:
      {{ formatDate(station.latestDetectionAt) }}
      @
      {{ formatTimestamp(station.latestDetectionAt) }}

      <em>({{ this.timeSince(station.latestDetectionAt) }})</em>
    </p>
    <p v-else>No detections yet.</p>
  </div>
</template>

<script>
import { formatTimestamp, formatDate, timeSince } from '@/lib/formatting'

export default {
  props: {
    station: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatTimestamp,
    formatDate,
    timeSince
  },
}
</script>
