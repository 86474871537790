<template>
  <div class="d-flex align-items-center">
    <!-- <PUCIcon style="width: 24px" class="me-2" /> -->

    <p v-if="!sensorData && $apollo.queries.sensorData.loading">
      Loading sensor data...
    </p>

    <p v-else-if="sensorData">
      Temperature: {{ formatTemperature(environment.temperature) }} |
      Humidity: {{ environment.humidity.toFixed(1) }}% |
      Pressure: {{ Math.round(environment.barometricPressure) }} hPa<br />
      AQI: {{ Math.round(environment.aqi) }} |
      Light: {{ light.clear }} ({{ light.nir }} nIR)

      <em>(Last updated {{ timeSinceUpdate.humanize() }} ago)</em>
    </p>
  </div>
</template>

<script>
import moment from 'moment'

import StationSensorsQuery from '@/queries/StationSensors.graphql'

import { formatTemperature } from '@/lib/units'

export default {
  props: {
    station: {
      type: Object,
      required: true
    },

    autoload: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      skipSensors: !this.autoload,
      sensorData: null
    }
  },

  apollo: {
    sensorData: {
      query: StationSensorsQuery,

      variables() {
        return {
          stationId: this.station.id
        }
      },

      skip() {
        return this.skipSensors
      }
    }
  },

  computed: {
    environment () { return this.sensorData.sensors.environment },
    light () { return this.sensorData.sensors.light },

    timeSinceUpdate() {
      const updatedAt = moment.parseZone(this.environment.timestamp)
      const now = moment(new Date())
      return moment.duration(now.diff(updatedAt))
    }
  },

  methods: {
    formatTemperature,

    loadData () {
      this.skipSensors = false
      this.$apollo.queries.sensorData.refetch()
    }
  }
}
</script>
